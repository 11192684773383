@media (max-width:1199px){
    .slider_marques{
        padding: 0 50px;
    }
    .slick-prev{
        left: 10px;
    }
    .slick-next{
        right:10px;
    }
    .nav_container {
        padding: 0px;
    }
    .gallery_link{
        /*width: 33%;
        &:nth-of-type(2){
            width:33%;
        }*/
    }
    .gallery_home{
        overflow: hidden;
    }
    .megamenu{
        .dropdown-menu{
            right: 0;
            left:inherit;
        }
    }
}
@media (max-width:991px){
    .bloc_promo{
        width: 360px;
        margin-left: auto;
    }
    .link_footer{
        text-align: center;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
    .product-container {
        height: auto;
    }
    #services{
        width: 100%;
    }
    .fixed-service,
    .unfixed-service{
        position: relative;
        top: inherit;
        bottom: inherit;
        margin-top: 40px;
    }
    .item_nav{
        @include font-size(15px);
    }
    .link_menu{
        &:after{
            content:"";
        }
    }
}
@media (max-width:767px){
    .produit .slick-dots{
        display: none !important;
    }
    .picto-right {
        width: 70%;
    }
    .p70_80{
        padding: 40px 0 70px;
    }
    .bg_marque{
        height: 580px;
    }
    .logo_marque{
        margin-bottom: 40px;
    }
    .labels{
        li{
            width: 80px;
            &:nth-child(-n+3) {
                margin-bottom: 10px;
            }
            &:nth-child(3) {
                margin-right: 15px;
            }
            &:nth-child(7) {
                margin-right: 15px;
            }
        }
        img{
            width: 100%;
        }
    }
    .pictos-container {
        .ico-picto {
            margin-right: 10px;
        }
        .tel{
            @include font-size(20px);
        }
        .picto{
            @include font-size(14px);
        }
    }
    .list-service{
        @include font-size(16px);
    }
    .customHeight{
        height: auto !important;
    }
    .slider_produit .img_container,
    .video iframe {
        height: 400px;
    }
    .fancybox-close {
        right: -10px;
        top: -22px;
    }
    .gallery_link {
        width: 100%;
        display: block;
        margin: 0 auto 10px;
        text-align: center;
        &:nth-of-type(2) {
            width: 100%;
        }
    }
    .white_text--18{
        @include font-size(16px);
    }
    .bg_title{
        height: 200px;
    }
    .h1_bg{
        padding-top: 25px;
    }
    .slider_home {
        display: none;
        .item {
            height: 580px;
        }
    }
    .bloc_promo-container {
        position: relative;
        bottom:0;
        right:0;
        background-color: #fff;
    }
    .bloc_promo {
        margin-top:202px;
        width: 100%;    
        right: 0px !important;
    }
    .footer_social{
        display: block;
        text-align: center;
        margin: 0 auto 20px;
    }
    .mentions{
        display: block;
        text-align: center;
        margin: 0 auto 15px;
    }
    .clics{
        display: block;
        text-align: center;
        margin: 0 auto 15px;
    }
    .social_container{
        display: none;
    }
    .header + .filler {
        height: 180px;
    }
    .nav_header {
        height: 45px;
    }
    .collapse{
        display: block !important;
    }
    .nav_container{
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 50px;
        background-color: $bg_color_dark2;
    }
    .nav_container{

        visibility: hidden;
        @include transform(translateY(-100%));
        @include transition(all,250ms,ease-in-out,0s);
    }
    .is-active{
        @include transform(translateY(0));
        visibility: visible;
    }
    .close-menu{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 50px;
        height: 50px;
        text-align: center;
        z-index: 4;
        .fa{
            color:$font_color_white;
            @include font-size(40px);
            @include rotate(45);
            &:hover,
            &:focus{
                color:$font_color_pink;
            }
        }
    }
    .dropdown-menu,
    .types{
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    #magic-line{
        display: none;
    }
    .link_menu{
        color:$font_color_white;
        line-height: 50px;
    }
    .item_nav{
        width: 100%;
        padding:0 30px;
        border-bottom: 1px solid rgba(255,255,255,0.2);
    }
    /*.open > .dropdown-menu {
        display: none;
    }*/
    .is-hidden{
        @include transform(translateX(100%));
    }
    .megamenu{
        .dropdown-menu,
        .types{
            background-color: $bg_color_dark2;
            top: 0;
            overflow-y: scroll;
            @include transition(all,250ms, ease-in-out,0s);
        }
        .dropdown-menu{
            z-index: 5;
        }
        .types{
            z-index: 10;
            li{
                padding: 0 20px;
            }
            .type{
                @include font-size(16px);
                line-height: 45px;
                text-align: center;
                color:$font_color_white;
                &:hover,
                &:focus{
                    color:$font_color_pink;
                }
            }
        }
        .marque{
            color:$font_color_white;
            position: relative;
            display: block;
            font-family: $font_default_light;
            cursor:pointer;
            &:after{
                content:"\f105";
                color:$font_color_white;
                font-family: 'fontAwesome';
                @include font-size(16px);
                position: absolute;
                //float:right;
                right: 15px;
                top: 0;
            }
            &:hover,
            &:focus{
                color:$font_color_pink;
                &:after{
                    color:$font_color_pink;
                }
            }
        }
        .dropdown-toggle,
        .dropdown-resp{
            position: relative;
            &:after{
                content:"\f105";
                color:$font_color_white;
                font-family: 'fontAwesome';
                @include font-size(16px);
                position: absolute;
                //float:right;
                right: 15px;
                top: 0;
                @include transition(all,120ms,ease-in-out,0s);
            }
        }
        .dropdown-resp{
            & + .dropdown-menu{
                display: none;
                position: relative;
                width: 100%;
                transition:none;
                border:none;
                box-shadow: none;
                z-index: 1;
                & > li > a{
                    color:$font_color_white;
                    line-height: 30px;
                    &:hover,
                    &:active{
                        color:$font_color_pink;
                    }
                }
            }
            & + .is-hidden{
                @include transform(translateX(0));
            }
        }
        .arrowDown{
            &:after{
                @include rotate(90);
            }
        }
        .col{
            width: 100%;
        }
        .marque-content{
            padding:0 20px;
            line-height: 50px;
            border-bottom: 1px solid rgba(255,255,255,0.2);
            margin-bottom: 0;
            &:last-of-type{
                margin-bottom:0px;
            }
        }
        .type{
            display: block;
            width: 100%;
        }
        .go-back-menu,
        .go-back{
            line-height: 50px;
            border-bottom:1px solid rgba(255,255,255,0.2);
            margin-bottom: 15px;
            position: relative;
            &:before{
                content:"\f104";
                color:$font_color_white;
                font-family: 'fontAwesome';
                @include font-size(16px);
                margin-right: 15px;
            }
        }
    }
    .megamenu-content{
        padding:0px;
    }/*
    .padding-mobile{
    }*/
    .go-back-menu,
    .go-back{
        display: block;
        padding:0 20px;
        a{
            color:$font_color_white;
            display: inline-block;
            &:hover,
            &:focus{
                color:$font_color_pink;
            }
        }
    }
}
@media (max-width:400px){
    .labels .block {
        display: none;
    }
}
@media (min-width:768px){
    .go-back{
        display: none;
    }
}