.footer {
    border-top:1px solid $bg_color_grey;
    background-color: $bg_color_dark2;
}
.footer_top{
    padding: 40px 0;
}
.footer_bottom{
    padding: 30px 0;
    border-top: 1px solid $bg_color_dark3;
}
.link_footer{
    color:$font_color_white;
    margin-bottom: 8px;
    &:hover,
    &:focus{
        color:$font_color_pink;
    }
}
span.link_footer{
    &:hover,
    &:focus{
        color:$font_color_white;
    }
}
.list_footer{
    padding-left: 18px;
    position: relative;
    line-height: 24px;
    &:before{
        content:"•";
        color:$font_color_white;
        position: absolute;
        left: 12px;
    }
}
.clics{
    height: 26px;
    vertical-align: middle;
}
.link_3clics{
    @include font-size(25px);
    color:$font_color_white;
    &:hover,
    &:focus{
        color:$font_color_pink;
    }
}
.mentions{
    color:$font_color_white;
}