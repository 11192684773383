@mixin transition($target, $duration, $easing, $delay) {
    -webkit-transition: $target $duration $easing $delay;
       -moz-transition: $target $duration $easing $delay;
        -ms-transition: $target $duration $easing $delay;
            transition: $target $duration $easing $delay;
}

@mixin border-radius($pixel) {
    -webkit-border-radius: $pixel;
       -moz-border-radius: $pixel;
            border-radius: $pixel;

        -ms-border-radius: $pixel;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: '') {
    -webkit-box-shadow: $top $left $blur $color #{$inset};
       -moz-box-shadow: $top $left $blur $color #{$inset};
            box-shadow: $top $left $blur $color #{$inset};
}

@mixin transform($transforms) {
    -webkit-transform: $transforms;
       -moz-transform: $transforms;
        -ms-transform: $transforms;
         -o-transform: $transforms;
            transform: $transforms;
}
@mixin transform-origin ($origin) {
    -webkit-transform-origin: $origin;
       -moz-transform-origin: $origin;
        -ms-transform-origin: $origin;
         -o-transform-origin: $origin;
            transform-origin: $origin;
}

@mixin opacity($opacity) {
    opacity: $opacity;

    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie);
}
//Scale
@mixin scale($echelle) {
    @include transform(scale(#{$echelle}echelle));
}

//Rotation
@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

//Font size
@function calculateRem($size) {
    $remSize: $size / 10px;
    @return $remSize * 1rem;
}
@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}
@-webkit-keyframes ease-in-back {
    0% { right: -120%;}
    70% {right:15%}
    100% {
       right: 5%;
    }
}
@-moz-keyframes ease-in-back {
  0%   { right: -120%; }
  70% {right:15%}
  100% {right: 5%; }
}
@-o-keyframes ease-in-back {
  0%   { right: -120%; }
  70% {right:15%}
  100% {right: 5%; }
}
@keyframes ease-in-back {
  0%   { right: -120%; }
  70% {right:15%}
  100% {right: 5%; }
}

@mixin ease-in-back($duration) {
    -webkit-animation:ease-in-back $duration 2s forwards;
    -moz-animation:ease-in-back $duration 2s forwards;
    -ms-animation:ease-in-back $duration 2s forwards;
    animation:ease-in-back $duration 2s forwards;
}

/*Pop IN effect*/
@-webkit-keyframes pop-in {
    0% {
        -webkit-transform: scale(.1); 
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1); 
        opacity: 1;
    }
}
@-moz-keyframes pop-in {
    0% {
        -moz-transform: scale(.1); 
        opacity: 0;
    }
    100% {
        -moz-transform: scale(1); 
        opacity: 1;
    }
}
@keyframes pop-in {
    0% {
        transform: scale(.1); 
        opacity: 0;
    }
    100% {
        transform: scale(1); 
        opacity: 1;
    }
}

@mixin pop-in($duration) {
    -webkit-animation:pop-in $duration;
    -moz-animation:pop-in $duration;
    -ms-animation:pop-in $duration;
    animation:pop-in $duration;
}
