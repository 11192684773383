input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #bbb;
    @include font-size(18px);
    font-style:italic;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
    opacity: 1;
    /* Mozilla Firefox 4 to 18 */
    color: #bbb;
    @include font-size(18px);
    font-style:italic;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    /* Mozilla Firefox 19+ */
    color: #bbb;
    @include font-size(18px);
    font-style:italic;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bbb;
    @include font-size(18px);
    font-style:italic;
}
.noSelect {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
}

.clear {
    clear: both;
    width: 100%;
}
.floatLeft {
    float: left!important;
}
.floatRight {
    float: right!important;
}
.verticalAligned {
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
}
.dispTable {
    display: table !important;
}
.hasBefore {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        display: inline-block;
    }
}
.hasAfter {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        display: inline-block;
    }
}
.xDistribution {
    font-size: 0;
    line-height: 0;
    text-align: justify;
    -ms-text-justify: distribute-all-lines;
        text-justify: distribute-all-lines;
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
    }
    & > * {
        display: inline-block;
        font-size: 14px;
        font-size: 1.4rem;
        line-height: normal;
        text-align: left;
    }
}
// .valign{
//  &:before{
//      content: '';
//      display: inline-block;
//      vertical-align: middle;
//      height: 100%;
//  }
// }

.center-line {
    width: 100%;
    text-align: center;
}
sup {
    font-size: .6em !important;
}
.no-overflow {
    overflow: hidden !important;
}
.uppercase {
    text-transform: uppercase;
}
.btn {
    @include transition(all, 200ms, ease, 0s);
}
.mw100 {
    max-width: 100%;
}
.paddL0 {
    padding-left: 0 !important;
}
.relative{
    position: relative !important;
}
.h100{
    height: 100%;
}
.table{
    margin-bottom: 0;
    display: table;
}
.table-cell{
    display: table-cell;
    height: 100%;
}
.vam{
    vertical-align: middle;
}
.mb0{
    margin-bottom: 0;
}
.mb40{
    margin-bottom: 40px;
}
.mb70{
    margin-bottom: 70px;
}
.mt35{
    margin-top: 35px;
}
.mt70{
    margin-top: 70px;
}
.mt100{
    margin-top: 100px;
}
.fit_responsive{
    max-width: 100%;
}