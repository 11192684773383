.bg_home{
    background-color: #000;
    position: relative;
}
.bg_grey{
    background-color: $bg_color_grey;
}
.bg_dark{
    background-color: $bg_color_dark2;
}
.bg_white{
    background-color: $bg_color_white;
}
.h2{
    text-transform: uppercase;
    @include font-size(36px);
    color:$font_color_dark2;
    font-family: $font_default_light;
}
.bloc_promo-container{
    position: absolute;
    bottom: 35px;
    right: 0px;
}
.bloc_promo{
    background-color: $bg_color_white;
    padding: 40px 22px;
    display: block;
    color:$font_color_dark2;
    position: relative;
    overflow: hidden;
    @include ease-in-back(800ms);
        right: -120%;
    &:hover,
    &:focus{
        color:$font_color_dark2;
        .plus{
            @include transform(translate(0));
        }
    }
    .desc{
        margin-bottom: 0;
        min-height:57px;
    }
}
.bloc_title{
    width: 100%;
    text-align: center;
    display: block;
    font-family: $font_default_bold;
    color:$font_color_pink;
    @include font-size(30px);
    line-height: 24px;
    text-transform: uppercase;
}
%separator_options{
    height: 12px;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    margin: 15px 0;
}
.separator{
    @extend %separator_options;
    background-image: url(../images/separator.png);
}
.separator_white{
    @extend %separator_options;
    background-image: url(../images/separator_white.png);
    margin: 30px 0;
}
.figure{
    margin-bottom: 20px;
    .img_promo{
        width: 100%;
        height: auto;
    }
}
.plus{
    font-family: $font_default_extralight;
    @include font-size(36px);
    position: absolute;
    right: 7px;
    bottom: 7px;
    line-height: 13px;
    height: 15px;
    z-index: 1;
    display: block;
    color:$font_color_white;
    @include transform(translate(30px,30px));
    @include transition(all,250ms,ease-in-out,0s);
    &:before{
        content:"";
        position: absolute;
        background-color: $bg_color_pink;
        display: block;
        z-index: -1;
        width: 70px;
        height: 70px;
        bottom: -41px;
        right: -41px;
        @include border-radius(50%);
    }
}
.item_logo-container{
    height: 113px;
}
.item_logo{
    margin: 0 auto;
    vertical-align: middle;
}
.slider_home{
    @include box-shadow(0,2px,5px,0,rgba(0,0,0,0.1));
    .item{
        height: 880px;
    }
}
%slick_options{
    background-color: transparent;
    background-repeat: no-repeat;
    border:none;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 50%;
    margin-top: -18px;
    z-index: 1;
    &:after{
        content:url(../images/arrow_right.png);
        right: 50%;
        position: absolute;
        margin-right: -6px;
        top: 50%;
        margin-top: -9px;
    }
}
.slider_marques{
    margin-bottom: 55px;
    .slick-slide{
        margin-right: 10px;
    }
}
.slick-prev{
    @extend %slick_options;
    background-image: url(../images/bg_next.png);
    @include rotate(180);
    left: -70px;
}
.slick-next{
    @extend %slick_options;
    background-image: url(../images/bg_next.png);
    right: -70px;
}
.slick-prev-black{
    @extend %slick_options;
    background-image: url(../images/bg_next-black.png);
    @include rotate(180);
    left: 30px;
}
.slick-next-black{
    @extend %slick_options;
    background-image: url(../images/bg_next-black.png);
    right: 30px;
}
.p70_80{
    padding: 70px 0 80px;
}
.pink_title{
    color:$font_color_pink;
    margin-bottom: 25px;
    @include font-size(20px);
}
.white_text{
    color:$font_color_white;
}
.pink_text{
    color:$font_color_pink;
}
.gallery_home{
    margin:20px 0;
    display: block;
}
.gallery_link{
    display: inline-block;
    vertical-align: top;
    margin-right: 1px;
    &:nth-of-type(2){
        width: 265px;
        overflow: hidden;
    }
}
.signature{
    font-family: $font_special;
    @include font-size(48px);
    color:$font_color_white;
    text-align: right;
    width: 100%;
    display: block;
}
#map{
    height: 370px;
    width: 100%;
}
.text18{
    @include font-size(18px);
}
.mb30{
    margin-bottom: 30px;
}
.m20_10{
    margin: 20px 0 10px;
}
.m60_10{
    margin: 60px 0 10px;
}
.infos-container{
    padding: 30px 25px;
}
.plain_text{
    margin-bottom: 0;
}
.vam{
    display: table;
    height: 100%;
    width: 100%;
    .link_logo{
        display: table-cell;
        vertical-align: middle;
    }
}