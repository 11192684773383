@font-face {
    font-family: 'ralewayextralight';
    src: url('fonts/raleway-extralight-webfont.eot');
    src: url('fonts/raleway-extralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/raleway-extralight-webfont.woff2') format('woff2'),
         url('fonts/raleway-extralight-webfont.woff') format('woff'),
         url('fonts/raleway-extralight-webfont.ttf') format('truetype'),
         url('fonts/raleway-extralight-webfont.svg#ralewayextralight') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'ralewaybold';
    src: url('fonts/raleway-bold-webfont.eot');
    src: url('fonts/raleway-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/raleway-bold-webfont.woff2') format('woff2'),
         url('fonts/raleway-bold-webfont.woff') format('woff'),
         url('fonts/raleway-bold-webfont.ttf') format('truetype'),
         url('fonts/raleway-bold-webfont.svg#ralewaybold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ralewaylight';
    src: url('fonts/raleway-light-webfont.eot');
    src: url('fonts/raleway-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/raleway-light-webfont.woff2') format('woff2'),
         url('fonts/raleway-light-webfont.woff') format('woff'),
         url('fonts/raleway-light-webfont.ttf') format('truetype'),
         url('fonts/raleway-light-webfont.svg#ralewaylight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ralewayregular';
    src: url('fonts/raleway-regular-webfont.eot');
    src: url('fonts/raleway-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/raleway-regular-webfont.woff2') format('woff2'),
         url('fonts/raleway-regular-webfont.woff') format('woff'),
         url('fonts/raleway-regular-webfont.ttf') format('truetype'),
         url('fonts/raleway-regular-webfont.svg#ralewayregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'mr_de_havilandregular';
    src: url('fonts/mrdehaviland-regular-webfont.eot');
    src: url('fonts/mrdehaviland-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/mrdehaviland-regular-webfont.woff2') format('woff2'),
         url('fonts/mrdehaviland-regular-webfont.woff') format('woff'),
         url('fonts/mrdehaviland-regular-webfont.ttf') format('truetype'),
         url('fonts/mrdehaviland-regular-webfont.svg#mr_de_havilandregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: '3clics';
    src:    url('fonts/3clics.eot?h8cbo2');
    src:    url('fonts/3clics.eot?h8cbo2#iefix') format('embedded-opentype'),
        url('fonts/3clics.ttf?h8cbo2') format('truetype'),
        url('fonts/3clics.woff?h8cbo2') format('woff'),
        url('fonts/3clics.svg?h8cbo2#3clics') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '3clics' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-decoration: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:hover,&:active,&:focus{text-decoration: none;}
}

.icon-3clics:before {
    content: "\e900";
}