.arianne{
    margin-top: 40px;
    margin-left: 10px;
}
.arianne_link{
    color:$font_color_grey3;
    @include font-size(12px);
    position: relative;
    &.current{
        color:$font_color_pink;
        &:after{
            background-color:$font_color_pink;
        }
        &:hover,
        &:focus{
            color:$font_color_pink;
            &:after{
                background-color: $font_color_pink;
            }
        }
    }
    &:after{
        content:"";
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: -4px;
        left: 0;
        background-color:$font_color_grey3;
        @include transition(all,120ms,ease-in-out,0s);
    }
    &:hover,
    &:focus{
        color:$font_color_pink;
        &:after{
            background-color: $font_color_pink;
        }
    }
}
span.arianne_link{
    &:hover,
    &:focus{
        color:$font_color_grey3;
        &:after{
            background-color: $font_color_grey3;
        }
    }
}
.dot{
    color:$font_color_grey3;
    margin:0 10px;
}
.dots{
    width: 16px;
    height: 16px;
    @include border-radius(50%);
    background-color: rgba(200, 200, 200, 1);
    box-shadow: 1px 2px 3px rgba(0,0,0,0.2);
    display: inline-block;
    position: relative;
    cursor:pointer;
    .img_dots-container{
        display: none;
        position: absolute;
        border:2px solid $bg_color_white;
        bottom: 21px;
        left:50%;
        margin-left: -45px;
        width: 90px;
        height: 90px;
        .img_dots{
            height: 100%;
            background-size: cover;
            background-position: center;
        }
        &:before{
            content:"";
            position: absolute;
            bottom: -14px;
            left: 50%;
            margin-left: -3px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 14px 3px 0 3px;
            border-color: $bg_color_white transparent transparent transparent;
        }
    }
    &:hover,
    &:focus{
        .img_dots-container{
            display: block;
        }
    }
}
.slick-dots{
    position: absolute;
    left: 50%;
    bottom: 50px;
    max-width: 500px;
    text-align: center;
    line-height: 20px;
    li{
        display: inline-block;
        margin-right: 8px;
        &:last-child{
            margin-right: 0;
        }
    }
}
.slick-active{
    .dots{
        background-color: rgba(255,255,255,1);
    }
}
.product-container{
    height: 720px;
}
.bg_marque{
    background-size: cover;
    height: 720px;
    width: 100%;
}
.detail_marque{
    display: table;
    text-align: center;
    width: 100%;
    padding: 0 15px;
    height: calc(100% - 60px);
}
.valign{
    display: table-cell;
    vertical-align: middle;
    height: 100%;
}
.h1{
    @include font-size(30px);
    font-family: $font_default_light;
    text-transform: uppercase;
    color:$font_color_dark2;
}
.logo_marque{
    margin-bottom: 50px;
}
.labels{
    li{
        display: inline-block;
        margin-right: 15px;
        &:nth-child(-n+3){
            margin-bottom: 25px;
        }
        &:nth-child(3),
        &:nth-child(7){
            margin-right: 0;
        }
    }
    .block{
        display: block;
    }
}
.slider_produit{
    .img_container{
        height: 720px;
    }
}
.small_title{
    @include font-size(18px);
    font-family: $font_default_bold;
}
.small-text{
    @include font-size(11px);
    text-transform: none;
}
.picto-right{
    display: inline-block;
    vertical-align: middle;
    line-height: 1em;
}
.services-container{
    padding:40px 25px;
    .pink_title{
        margin-bottom: 20px;
        display: block;
    }
}
.list-service{
    @include font-size(18px);
    line-height: 24px;
    margin-bottom: 15px;
    padding-left: 15px;
    position: relative;
    &:before{
        content:"+";
        position: absolute;
        left:1px;
        top:0px;
    }
}
.pictos-container{
    .picto{
        margin-top: 20px;
        text-transform: uppercase;
        @include font-size(16px);
    }
    .ico-picto{
        margin-right: 15px;
    }
    .tel{
        @include font-size(24px);
    }
    .picto-text{
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 57px;
        height: 57px;
        padding-top: 9px;
        position: relative;
        font-family: $font_default_bold;
        font-style:inherit;
        line-height: 20px;
        @include font-size(24px);
        &:before{
            content:"";
            width: 57px;
            height: 57px;
            border:1px solid $bg_color_dark2;
            display: block;
            position: absolute;
            top: 0;
            @include border-radius(50%);
        }
        &:after{
            content:"ans";
            display: block;
            text-transform: uppercase;
            @include font-size(12px);
        }
    }
    .multiple{
        line-height: 18px;
        @include font-size(14px);
        padding-top: 13px;
    }
}
.services-container{

    width: 100%;
}
.fixed-service{
    position:fixed;
    top: 95px;
}
.unfixed-service{
    position: absolute;
    bottom: 0;
    width: 100%;
}
#services{
    width: 390px;
}
.fancybox-overlay {
    background-color: rgba(0, 0, 0, 0.8);
}
.fancybox-nav span{
    visibility: visible;
}
.fancybox-next span {
    @extend .slick-next;
    background-image: url(../images/bg_next.png);
    background-position: 0 0;
    z-index: 5;
    &:after{
        content: url(../images/arrow_right-black.png);
        z-index: 10;
    }
}
.fancybox-prev span {
    @extend .slick-prev;
    background-image: url(../images/bg_next.png);
    background-position: 0 0;
    &:after{
        content: url(../images/arrow_right-black.png);
    }
}
.fancybox-close {
    right: -100px;
    width: 20px;
    height: 20px;
    &:before{
        content: "";
        background-image: url(../images/arrow_right.png);
        width: 6px;
        height: 18px;
        display: inline-block;
    }
    &:after{
        content: "";
        background-image: url(../images/arrow_right.png);
        @include transform(rotate(180deg));
        width: 7px;
        height: 18px;
        display: inline-block;
    }
}
#fancybox-thumbs ul li{
    margin-right: 15px;
    &:last-child{
        margin-right: 0;
    }
    &.active {
        opacity: 0.75;
        padding: 0;
        border: 0;
        a{
                border:2px solid $bg_color_white;
            img{
            }
        }
    }
}
.btn_contact-container .btn{
    text-transform: inherit;
    @include font-size(20px);
    font-family: $font_default_regular;
    padding: 13px 30px;
    white-space:normal;
    height: auto;
}
.btn-white{
    background-color: transparent;
    border:1px solid $bg_color_white;
    line-height: inherit;
    display: inline-block;
    padding: 15px 30px;
    color:$font_color_white;
    text-transform: uppercase;
    font-family: $font_default_light;
    @include font-size(18px);
    &:hover,
    &:focus{
        color:$font_color_pink;
        border-color:$bg_color_pink;
        .picto-book-hover{
            opacity: 1;
        }
    }
}
.picto{
    position: relative;
}
.picto-book{
    height: 26px;
    width: 32px;
    display: inline-block;
    margin-right: 15px;
    background-image: url("../images/picto-book.png");
    background-position: 0 0;
    vertical-align: text-bottom;
}
.picto-book-hover{
    position: absolute;
    top: 0;
    right: 0;
    background-position: 0 -26px;
    margin-right: 0;
    opacity: 0;
    @include transition(all,120ms,ease-in-out,0s);
}
.white_text{
    a{
        color:#fff;
        text-decoration: underline;
        &:hover,
        &:focus{
            color:$font_color_pink;
        }
    }
}
.video{
    iframe{
        height: 720px;
        width: 100%;

    }
}
