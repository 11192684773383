//Font style
$font_default_regular: 'ralewayregular', Helvetica, sans-serif;
$font_default_bold: 'ralewaybold', Helvetica, sans-serif;
$font_default_light: 'ralewaylight', Helvetica, sans-serif;
$font_default_extralight: 'ralewayextralight', Helvetica, sans-serif;
$font_special: 'mr_de_havilandregular', Helvetica, sans-serif;

//Couleurs font
$font_color_white: #fff;
$font_color_offwhite: #f5f5f5;
$font_color_dark: #000;
$font_color_dark2: #242424;
$font_color_grey: #bdc1c2;
$font_color_grey2: #808080;
$font_color_grey3: #9a9a9a;
$font_color_pink: #e6007b;


//Page style
$bg_color_white: #fff;
$bg_color_offwhite: #f5f5f5;
$bg_color_dark: #000;
$bg_color_dark2: #242424;
$bg_color_dark3: #474747;
$bg_color_grey: #d0d0d0;
$bg_color_grey2: #bbb;
$bg_color_grey_light: #eaeaea;
$bg_color_pink: #e6007b;

//Réseaux sociaux
$facebook: #3b5998;
$twitter: #32ccfe;
$google: #d2352c;
$rss: #ff6e00;
