
.bg_title{
    height: 220px;
    background-color: $bg_color_dark2;
    background-position: center;
    background-size: cover;
    position: relative;
    .arianne{
        position: absolute;
        left: 0;
        right: 0;
        margin-left: 0;
        z-index: 10;
    }
    /*.h100{
        display: table;
    }*/
}
.historique{
    .bg_title{
        background-image: url(../images/bg_historique.jpg);
    }
}
.plan{
    .bg_title{
        background-image: url(../images/bg_plan.jpg);
    }
}
.contact{
    .bg_title{
        background-image: url(../images/bg_contact.jpg);
    }
}
.promotions{
    .bg_title{
        background-image: url(../images/bg_promotions.jpg);
    }
}
.mentions{
    .bg_title{
        background-image: url(../images/bg_mentions.jpg);
    }
}
.h1_bg{
    @extend .h1;
    color:$font_color_white;
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    display: flex;
    align-items: center;
}
.slider_historique{
    .img_container{
        height: 425px;
    }
    .slick-dots{
        bottom: 20px;
    }
}
.quote{
    font-family: $font_default_light;
    @include font-size(18px);
    color:$font_color_white;
    margin: 40px 0;
}
.white_text--18{
    color:$font_color_white;
    @include font-size(18px);
}
.p60_200{
    padding: 60px 0 200px;
}
.p60_100{
    padding: 60px 0 100px;
}
#map_div{
    height: 580px;
}
.ml-15{
    margin-left: -15px;
}
.text{
    @include font-size(18px);
    color:$font_color_dark2;
    font-family: $font_default_regular;
}
.bus-icon{
    padding-left: 25px;
    position: relative;
    &:after{
        content:"\f207";
        position: absolute;
        left: 0;
        top: 0;
        font-family: 'fontAwesome';
        @include font-size(18px);
        color:$font_color_dark2;
    }
}
.flocon-icon{
    &:after{
        content:url('../images/icon-flocon.png');
        margin-right: 8px;
        vertical-align: middle;
    }
}
.clim{
    margin-top: 5px;
    display: block;
    i{
        margin-bottom: -3px;
        display: inline-block;
    }
}
.plan{
    .infos-container{
        height: 580px;
        display: table;
        .dtc{
            display: table-cell;
            vertical-align: middle;
        }
    }
}
.default_input{
    height: 58px;
    border:1px solid $bg_color_grey2;
    background-color: $bg_color_dark2;
    width: 100%;
    padding:0 15px;
    color:$font_color_grey2;
    margin-bottom: 30px;
    @include font-size(18px);
    @include transition(all,250ms,ease-in-out,0s);
    &:hover{
        border-color:$bg_color_white;
    }
    &:active,
    &:focus{
        background-color: $bg_color_white;
        color:$font_color_dark2;
    }
}
.default_textarea{
    @extend .default_input;
    width: 100%;
    height: 158px;
    padding: 15px;
}
.btn{
    font-family: $font_default_bold;
    text-transform: uppercase;
    height: 60px;
    //line-height: 60px;
    padding: 15px 10px;
    text-align: center;
    border-radius:0;
    min-width: 270px;
    background-color:$bg_color_pink;
    color:$font_color_white;
    @include font-size(24px);
    &:hover,
    &:focus{
        background-color: $bg_color_white;
        color:$font_color_pink;
    }
}
.square-logo-container{
    width: 100%;
}
.square-logo{
    width: 150px;
    height: 150px;
    margin-right: 12px;
    margin-bottom: 15px;
    display: inline-flex;
    vertical-align: top;
    .dtc{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .logo-item{
        width: 100%;
        padding:0 10px;
    }
    &:nth-child(4){
        margin-right: 0;
    }
}
.bg_brown{
    background-color: #341e13;
}
.vertical-logo{
    .logo-item{
        padding: 0 20px;
    }
}