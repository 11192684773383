.header_home{
	position: absolute;
	top: 0;
	left: 0;
}
.header{
    position: absolute;
    top: 0;
    left: 0;
    + .filler{
        height: 232px;
        display: block;
    }
    .top_header{
        background-color: rgba(36,36,36,1);
    }
}
.navbar{
    margin: 0;
    border: none;
    border-radius:0px;
}
.top_header{
	padding: 15px 0 25px;
	background-color: rgba(36,36,36,.9);
}
.logo{
	display: block;
	margin: 0 auto;
}
.social_container{
	position: absolute;
	top: 50%;
	right: 15px;
}
.padding-mobile{
	padding-right: 100px;
}
.social_link{
	color: $font_color_white;
    font-family: $font_default_bold;
    @include font-size(16px);
	.fa{
		position: relative;
		vertical-align: middle;
        @include font-size(20px);
        margin-right: 15px;
        padding-right: 15px;
        &:before{
            @include transition(all,120ms,ease-in-out,0s);
        }
		&:after{
			content: '';
			position: absolute;
            top: -50%;
            left: -13px;
            display: block;
            width: 38px;
            height: 38px;
			border: 1px solid $bg_color_white;
            @include transition(all,120ms,ease-in-out,0s);
            @include border-radius(50%);
		}
	}
    &:hover,
    &:focus{
        color:$font_color_pink;
        .fa{
            &:after{
                border-color:$font_color_pink;
            }
            &:before{
                color:$font_color_pink;
            }
        }
    }
}
.slider_menu{
    .item{

        height: 250px;
    }
}
.nav_header{
    height: 80px;
    background-color: $bg_color_white;
}
.nav_container{
    //line-height: 80px;
    padding:0 90px;
}
.item_nav{
    font-family: $font_default_regular;
    text-transform: uppercase;
    @include font-size(16px);
}
#magic-line {
    position: absolute;
    bottom: 0px;
    top: -4px;
    width: 100px;
    height: 4px;
    background: $bg_color_pink;
}
.fixed{
    #magic-line{
        top:0;
    }
}
.link_menu{
    line-height: 80px;
    color:$font_color_dark2;
    display: block;
    &:hover,
    &:focus{
        color:$font_color_pink;
    }
    position: relative;
    &:before{
        content:"";
        position: absolute;
        right: -66px;
        width: 66px;
        height: 80px;
        background-color:transparent;
    }
    &:after{
        content:url('../images/capiton.png');
        position: absolute;
        right: -43px;
        top: 3px;
    }
    &.active{
        .link_menu{
            color:$font_color_pink;
        }
    }
}
.noCapiton{
    &:after{
        content:"";
    }
}
.fixed{
    position: fixed;
    top: 0;
    width: 100%;
}
.unfixed{
    position:relative;
}
.dropdown-toggle,
.dropdown-resp{
    cursor:pointer;
}
.dropdown-menu{
    width: 100%;
    > li{
        > a{
            padding: 10px 20px;
        }
    }
}
.megamenu{
  .nav, .collapse, .dropup, .dropdown {
    position: static;
  }
  .container {
    position: relative;
  }
  .dropdown-menu {
    left: 0;
    top: 79px;
    margin: 0;
    border-radius: 0;
    border-top: 1px solid $bg_color_grey_light;
  }
  .col{
      vertical-align: top;
  }
}
.megamenu-content{
    padding:50px 30px;
    /*.img_container{
        height: 216px;
    }*/
}
.marque-content{
    margin-bottom: 30px;
    &:last-of-type{
        margin-bottom: 0;
    }
}
.marque{
    text-transform: uppercase;
    font-family: $font_default_bold;
    @include font-size(16px);
    color:$font_color_dark2;
    cursor:default;
    &:hover,
    &:focus{
        color:$font_color_dark2;
    }
}
.types{
    padding:5px 0 0 10px;
}
.type{
    text-transform: none;
    @include font-size(14px);
    color:$font_color_grey2;
    line-height: 20px;
    &:hover,
    &:focus{
        color:$font_color_pink;
    }
}
.relative{
    .dropdown-menu{
        width: auto;
    }
}
.fancybox-overlay{
    background-color:rgba(36,36,36,0.5);
}
.menu-overlay{
    background-color:rgba(36,36,36,0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity:0;
    top: 0;
    @include transition(all,250ms,ease-in-out,0s);
}
.visible{
    opacity:1;
    z-index: 50;
}
.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
    color: $font_color_pink;
    text-decoration: none;
    background-color: transparent;
}
.navbar-default{
    .navbar-toggle {
        position: relative;
        float: none;
        display: block;
        margin:0 auto;
        padding: 9px 10px;
        background-color: transparent;
        background-image: none;
        border: 0;
        border-radius: 0px;
        .fa{
            @include font-size(30px);
        }
        &:hover,
        &:focus{
            background-color: transparent;
            color:$font_color_pink;
        }
    }
}